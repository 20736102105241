<template>
  <div class="content colors">
    <h1>Colors</h1>

    <div class="flex justify-between items-center">
      <b-tabs pills v-model="colorIndex" @input="onColorType">
        <b-tab
          v-for="colorType in colorTypes"
          :key="colorType.value"
          :title="colorType.text"
        ></b-tab>
      </b-tabs>
      <div class="flex gap-8 items-center">
        <div class="flex gap-2">
          <div class="flex flex-column gap-1">
            <div>Catalogue:</div>
            <button-select
              v-model="filters.page_source"
              :options="options.page_source"
              size="sm"
              @input="fetchColors()"
            ></button-select>
          </div>
        </div>

        <div class="flex gap-2">
          <div class="flex flex-column gap-1">
            <div>Sort by:</div>
            <button-select
              v-model="filters.sort"
              :options="options.sort"
              size="sm"
              @input="fetchColors()"
            ></button-select>
          </div>
        </div>
        <div class="flex gap-4 items-center">
          <div class="flex flex-col gap-2">
            <b-form-checkbox
              v-model="filters.has_recipe_french"
              name="has-recipe-french"
              value="1"
              unchecked-value=""
              @change="fetchColors()"
              class="text-nowrap"
            >
              Recipe French
            </b-form-checkbox>

            <b-form-checkbox
              v-model="filters.has_recipe_bekro"
              name="has-recipe-bekro"
              value="1"
              unchecked-value=""
              @change="fetchColors()"
              class="text-nowrap"
            >
              Recipe Bekro
            </b-form-checkbox>
          </div>

          <b-form-checkbox
            v-model="filters.is_favorite"
            name="is-favorite"
            value="1"
            unchecked-value=""
            @change="fetchColors()"
            class="text-nowrap"
          >
            Favorite
          </b-form-checkbox>
        </div>
        <div class="flex gap-2">
          <b-button
            variant="primary"
            size="sm"
            @click="onMixingOpen"
          >
            Corob
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            @click="exportColors"
            :disabled="loadingCount > 0"
          >
            Export
          </b-button>
        </div>
      </div>
    </div>

    <b-form-input
      v-model="filters.search"
      placeholder="Search"
      size="sm"
      autocomplete="off"
      trim
      type="search"
      class="mt-3"
      debounce="500"
      @change="fetchColors()"
    ></b-form-input>

    <div class="mt-3">
      <h3>Active filters:</h3>
      <div class="d-flex flex-wrap gap-2 mt-1" v-if="activeFilters.length > 0">
        <b-button
          variant="primary"
          size="sm"
          @click="reset()"
          :disabled="loadingCount > 0"
        >
          Clear all filters
        </b-button>
        <div v-for="activeFilter in activeFilters" :key="activeFilter.text">
          <b-input-group :prepend="activeFilter.text" size="sm">
            <b-input-group-append>
               <b-button
                  variant="primary"
                  size="sm"
                  @click="resetActiveFilter(activeFilter)"
                  :disabled="busy"
                  title="Remove filter"
                >
                  X
                </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <span v-if="activeFilters.length === 0">No filters applied.</span>
    </div>

    <table
      class="table table-google table-colors mt-3"
    >
      <thead>
        <tr>
          <th class="text-nowrap">ID</th>
          <th class="text-nowrap">Name</th>
          <th class="text-nowrap">Description</th>
          <th class="text-nowrap">Page</th>
          <th class="text-nowrap">Color</th>
          <th class="text-nowrap">Type</th>
          <th class="text-nowrap">Palette</th>
          <th class="text-nowrap">French</th>
          <th class="text-nowrap">Bekro</th>
          <th class="text-nowrap">Recipe</th>
          <th class="text-nowrap">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="row-fit text-nowrap">New color</td>
          <td>
            <b-form-input
              ref="newColorName"
              v-model="newColor.name"
              placeholder="Name"
              size="sm"
              autocomplete="off"
              @keyup.enter="addColor"
              trim
            ></b-form-input>
          </td>
          <td>
            <b-form-input
              v-model="newColor.description"
              placeholder="Description"
              size="sm"
              autocomplete="off"
              @keyup.enter="addColor"
              trim
            ></b-form-input>
          </td>
          <td>
            <b-form-input
              v-model="newColor.page"
              placeholder="Page"
              size="sm"
              autocomplete="off"
              @keyup.enter="addColor"
              trim
            ></b-form-input>
          </td>
          <td>
            <b-form-input
              v-model="newColor.hex"
              placeholder="Color (6-8 digit hex)"
              size="sm"
              autocomplete="off"
              @keyup.enter="addColor"
              trim
              :style="{ 'border-color': newColorExistsAlready ? 'red' : 'transparent' }"
            ></b-form-input>
          </td>
          <td class="row-fit">{{ newColor.type }}</td>
          <td>
            <b-form-select
              v-model="newColor.palette"
              :options="colorPalettes"
              size="sm"
              class="select-palette"
            ></b-form-select>
          </td>
          <td colspan="3"></td>
          <td class="row-fit">
            <div class="flex justify-end">
              <b-button
                variant="primary"
                size="sm"
                @click="addColor"
                :disabled="newColor.loadingCount > 0 || newColorExistsAlready"
              >
                Add
              </b-button>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="6">
            <div class="font-bold">
              <span v-if="loadingCount > 0">Loading..</span>
              <span v-else-if="colors.length === 0">No colors</span>
              <span v-else>
                Showing {{ colors.length | format }} / {{ colorCount | format }} color(s)
              </span>
            </div>
          </td>
        </tr>

        <tr
          v-for="color in colors"
          :key="color.id"
        >
          <td class="row-fit">{{ color.id }}</td>

          <td>
            <b-form-input
              v-if="color.editing"
              v-model="color.name"
              placeholder="Name"
              size="sm"
              autocomplete="off"
              @keyup.enter="saveColor(color)"
              trim
            ></b-form-input>
            <span v-else>{{ color.name }}</span>
          </td>

          <td>
            <b-form-input
              v-if="color.editing"
              v-model="color.description"
              placeholder="Description"
              size="sm"
              autocomplete="off"
              @keyup.enter="saveColor(color)"
              trim
            ></b-form-input>
            <span v-else class="d-flex gap-4 align-items-center">
              <span>{{ color.description }}</span>
              <i v-if="color.is_favorite" class="fas fa-heart" title="Favorite color"></i>
            </span>
          </td>

          <td>
            <b-form-input
              v-if="color.editing"
              v-model="color.page"
              placeholder="Page"
              size="sm"
              autocomplete="off"
              @keyup.enter="saveColor(color)"
              trim
            ></b-form-input>
            <span v-else>{{ color.page }}</span>
          </td>

          <td>
            <b-form-input
              v-if="color.editing"
              v-model="color.hex"
              placeholder="Color (6-8 digit hex)"
              size="sm"
              autocomplete="off"
              @keyup.enter="saveColor(color)"
              trim
            ></b-form-input>
            <mask-display v-else :hex="color.hex" />
          </td>

          <td class="row-fit">
            <b-form-select
              v-if="color.editing"
              v-model="color.type"
              :options="colorTypeValues"
              size="sm"
              class="select-type"
            ></b-form-select>
            <span v-else>{{ color.type }}</span>
          </td>

          <td class="row-fit">
            <b-form-select
            v-if="color.editing"
              v-model="color.palette"
              :options="colorPalettes"
              size="sm"
              class="select-palette"
            ></b-form-select>
            <span v-else>{{ color.palette }}</span>
          </td>

          <td>
            <div v-if="color.has_recipe_french" class="text-green-darker">
              <i class="material-icons">done</i>
            </div>
          </td>

          <td>
            <div v-if="color.has_recipe_bekro" class="text-green-darker">
              <i class="material-icons">done</i>
            </div>
          </td>

          <td>
            <button
              v-if="color.has_recipe"
              class="px-2 flex items-center"
              @click="onRecipeOpen(color)"
            >
              <i class="material-icons">list_alt</i>
            </button>
          </td>

          <td>
            <div v-if="color.editing" class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                @click="saveColor(color)"
                :disabled="color.loadingCount > 0"
              >
                Save
              </b-button>

              <b-button
                variant="white"
                size="sm"
                @click="cancelColor(color)"
                :disabled="color.loadingCount > 0"
              >
                Cancel
              </b-button>
            </div>
            <div v-else class="flex gap-2">
              <b-button
                :variant="filters.similar === color.hex ? 'dark' : 'outline-dark'"
                size="sm"
                @click="findSimilar(color)"
                :disabled="busy"
              >
                <span v-if="filters.similar  === color.hex">Cancel</span>
                <span v-else>Similar</span>
              </b-button>

              <b-button
                variant="primary"
                size="sm"
                @click="editColor(color)"
                :disabled="color.loadingCount > 0"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteColor(color)"
                :disabled="color.loadingCount > 0"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="colorCount"
      :per-page="filters.perPage"
      @input="fetchColors(true)"
    ></b-pagination>

    <mixing-modal
      v-if="mixingModal.showModal"
      :data="mixingModal"
      :filters="filters"
      @apply="onMixingApply"
    />

    <recipe-modal
      v-if="recipeModal.showModal"
      :data="recipeModal"
    />
  </div>
</template>

<script>
import { downloadFile } from '@/helpers';

const MaskDisplay = () => import('@/components/designs/MaskDisplay.vue');
const MixingModal = () => import('@/components/colors/MixingModal.vue');
const RecipeModal = () => import('@/components/design/RecipeModal.vue');
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'Colors',
  components: {
    MaskDisplay,
    MixingModal,
    RecipeModal,
    ButtonSelect,
  },
  computed: {
    busy() {
      return this.loadingCount > 0;
    },
    pageCount() {
      return Math.ceil(this.colorCount / this.filters.perPage);
    },
    queryFilters() {
      return {
        type: this.colorType,
        mixing_french: this.mixing.french.join(','),
        mixing_bekro: this.mixing.bekro.join(','),
        ...this.filters,
      };
    },
    newColorExistsAlready() {
      const newColorHex = this.newColor.hex.toLowerCase();
      const newColorExistsAlready = this.colors
        .find((findColor) => findColor.hex === newColorHex) !== undefined;
      return newColorExistsAlready;
    },
    colorType() {
      return this.colorTypes[this.colorIndex].value;
    },
    activeFilters() {
      const activeFilters = [];
      if (this.filters.page_source !== '') {
        const catalogue = this.options.page_source.find(
          (c) => c.value === this.filters.page_source,
        );
        const catalogueName = catalogue ? catalogue.text : 'Unknown';
        activeFilters.push({
          text: `Catalogue: ${catalogueName}`, reset: () => { this.filters.page_source = ''; },
        });
      }
      if (this.filters.sort !== 'pantoneNumber') {
        const sortOption = this.options.sort.find((o) => o.value === this.filters.sort);
        const sortName = sortOption ? sortOption.text : 'Unknown';
        activeFilters.push({
          text: `Sort: ${sortName}`, reset: () => { this.filters.sort = 'pantoneNumber'; },
        });
      }
      if (this.filters.has_recipe_french !== '') {
        activeFilters.push({
          text: 'Recipe: French', reset: () => { this.filters.has_recipe_french = ''; },
        });
      }
      if (this.filters.has_recipe_bekro !== '') {
        activeFilters.push({
          text: 'Recipe: Bekro', reset: () => { this.filters.has_recipe_bekro = ''; },
        });
      }
      if (this.filters.is_favorite !== '') {
        activeFilters.push({
          text: 'Favorite', reset: () => { this.filters.is_favorite = ''; },
        });
      }
      if (this.mixing.french.length > 0) {
        activeFilters.push({
          text: `Mixing French: ${this.mixing.french.length} colors`, reset: () => { this.mixing.french = []; },
        });
      }
      if (this.mixing.bekro.length > 0) {
        activeFilters.push({
          text: `Mixing Bekro: ${this.mixing.bekro.length} colors`, reset: () => { this.mixing.bekro = []; },
        });
      }
      if (this.filters.search !== '') {
        activeFilters.push({
          text: `Search: ${this.filters.search}`, reset: () => { this.filters.search = ''; },
        });
      }
      if (this.filters.similar !== null) {
        activeFilters.push({
          text: `Similar: #${this.filters.similar}`, reset: () => { this.filters.similar = null; },
        });
      }
      if (this.filters.mixing_mode !== 'exclusive') {
        activeFilters.push({
          text: 'Mixing Mode: Contains', reset: () => { this.filters.mixing_mode = 'exclusive'; },
        });
      }
      return activeFilters;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {
        has_recipe_bekro: '',
        has_recipe_french: '',
        is_favorite: '',
        page: 1,
        page_source: '',
        perPage: 200,
        search: '',
        similar: null,
        mixing_mode: 'exclusive',
        sort: 'pantoneNumber',
      },
      options: {
        page_source: [
          { text: 'All', value: '' },
          { text: 'Coated', value: 'C' },
          { text: 'Neon', value: 'N' },
          { text: 'Metallic', value: 'M' },
        ],
        sort: [
          { text: 'Shade', value: 'default' },
          { text: 'Pantone #', value: 'pantoneNumber' },
          { text: 'Pantone Page', value: 'pantonePage' },
        ],
      },
      colors: [],
      colorCount: 0,
      colorIndex: 0,
      mixing: {
        french: [],
        bekro: [],
      },
      mixingModal: {
        showModal: false,
        mixing: {},
      },
      recipeModal: {
        showModal: false,
        color: null,
      },
      colorTypes: [
        { text: 'Wax', value: 'wax' },
        { text: 'Frits', value: 'frits' },
      ],
      colorPalettes: [
        { text: 'Opaque', value: 'opaque' },
        { text: 'Transparent', value: 'transparent' },
        { text: 'Iris', value: 'iris' },
      ],
      colorTypeValues: ['wax', 'frits'],
      newColor: {
        id: null,
        name: '',
        description: '',
        hex: '',
        type: 'wax',
        page: '',
        palette: 'opaque',
        loadingCount: 0,
      },
    };
  },
  methods: {
    reset() {
      this.filters.has_recipe_bekro = '';
      this.filters.has_recipe_french = '';
      this.filters.is_favorite = '';
      this.filters.page = 1;
      this.filters.page_source = '';
      this.filters.perPage = 25;
      this.filters.search = '';
      this.filters.similar = null;
      this.filters.mixing_mode = 'exclusive';
      this.filters.sort = 'pantoneNumber';
      this.mixing.french = [];
      this.mixing.bekro = [];
      this.fetchColors();
    },
    resetActiveFilter(activeFilter) {
      activeFilter.reset();
      this.fetchColors();
    },
    fetchColorData() {
      this.loadingCount++;
      this.$http
        .get('/color')
        .query(this.queryFilters)
        .then((res) => {
          res.body.colors.forEach((color) => {
            color.editing = false;
            color.loadingCount = 0;
          });
          this.colors = res.body.colors;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch colors: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchColorCount() {
      this.loadingCount++;
      this.$http
        .get('/color_count')
        .query(this.queryFilters)
        .then((res) => {
          this.colorCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch color count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchColors(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchColorCount();
        this.filters.page = 1;
      } else {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchColorData(paginate);
    },
    exportColors() {
      this.loadingCount++;
      this.$http
        .get('/color_export')
        .query(this.queryFilters)
        .then((res) => {
          downloadFile(res.body.link);
        })
        .catch((err) => {
          this.$toast.error(`Failed to export colors: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    resetNewColor() {
      this.newColor.description = '';
      this.newColor.hex = '';
      this.newColor.name = '';
      this.newColor.page = '';
    },
    addColor() {
      this.newColor.loadingCount++;
      this.$http
        .post('/color')
        .send({ color: this.newColor })
        .then(() => {
          this.reset();
          this.filters.search = this.newColor.hex;
          this.resetNewColor();
          this.fetchColors();
        })
        .catch((err) => {
          this.$toast.error(`Failed to add color: ${err.response.text}`);
        })
        .finally(() => {
          this.newColor.loadingCount--;
        });
    },
    saveColor(color) {
      color.loadingCount++;
      this.$http
        .post('/color')
        .send({ color })
        .then((res) => {
          Object.keys(res.body.color).forEach((key) => {
            color[key] = res.body.color[key];
          });
          color.editing = false;
        })
        .catch((err) => {
          this.$toast.error(`Failed to save color: ${err.response.text}`);
        })
        .finally(() => {
          color.loadingCount--;
        });
    },
    deleteColor(color) {
      const shouldDelete = confirm(`Do you really wish to delete '${color.name}' color?`);
      if (shouldDelete) {
        color.loadingCount++;
        this.$http
          .delete(`/color/${color.id}`)
          .then(() => {
            this.colors = this.colors.filter((filterColor) => filterColor.id !== color.id);
          })
          .catch((err) => {
            this.$toast.error(`Failed to delete color: ${err.response.text}`);
          })
          .finally(() => {
            color.loadingCount--;
          });
      }
    },
    cancelColor(color) {
      color.loadingCount++;
      this.$http
        .get(`/color/${color.id}`)
        .then((res) => {
          Object.keys(res.body.color).forEach((key) => {
            color[key] = res.body.color[key];
          });
          color.editing = false;
        })
        .catch((err) => {
          this.$toast.error(`Failed to cancel color: ${err.response.text}`);
        })
        .finally(() => {
          color.loadingCount--;
        });
    },
    editColor(color) {
      color.editing = true;
    },
    findSimilar(color) {
      if (this.filters.similar === color.hex) {
        this.filters.similar = null;
      } else {
        this.filters.similar = color.hex;
      }
      this.colors = [];
      this.filters.search = '';
      this.fetchColors();
    },
    onColorType() {
      this.filters.search = '';
      this.newColor.type = this.colorType;
      this.fetchColors();
    },
    onMixingOpen() {
      this.mixingModal.mixing = this.mixing;
      this.mixingModal.showModal = true;
    },
    onMixingApply(mixing) {
      this.mixing = mixing;
      this.fetchColors();
    },
    onRecipeOpen(color) {
      this.recipeModal.color = color;
      this.recipeModal.showModal = true;
    },
  },
  created() {
    this.fetchColors();
  },
};
</script>

<style lang="scss" scoped>
.colors {
  max-width: 1350px;
}

.select-type {
  width: 80px;
}

.select-palette {
  width: 120px;
}

.table-colors tbody tr:nth-child(odd):not(:first-child) {
  background-color: #fafafa;
}
</style>
